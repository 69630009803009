<template>
    <section class="xbts-download">
        <div class="card w-100">
            <div class="card-body" style="min-height:60vh;">
                <div class="row">
                    <div class="col-md-6">
                        <p>
                            <a target="_blank" href="https://github.com/XBTS/app/releases/latest" class="w-100 btn btn btn-success btn-rounded btn-fw">{{$t('app.download_app')}} Win, Mac, Nix, Android</a>
                        </p>


                        <p>
                            <a target="_blank" href="https://community.smartholdem.io/topic/904/defi-ammob-defi-with-automated-market-maker-order-book" class="w-100 btn btn btn-primary btn-rounded btn-fw">Help</a>
                        </p>

                    </div>
                    <div class="col-md-6">
                        <iframe src="https://snapcraft.io/xbts/embedded?button=black&channels=true" frameborder="0" width="100%" height="380px" style="border: 1px solid #CCC; border-radius: 2px;"></iframe>
                    </div>
                </div>



            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Download"
}
</script>

<style scoped>

</style>
